<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-3QFRYTYGL3"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-3QFRYTYGL3');
</script>

<header>
    <section class="navigation">
        <div class="nav-container">
            <div class="brand">
                <a class="image-link">
                    <img src="/assets/logo-cunori.png" class="image" alt="Logo Cunori">
                    <img src="/assets/logo_ingenieria.png" class="image" alt="Logo Ingeniería">
                    Pruebas Específicas de Ingeniería v1.0
                </a>
            </div>
            <nav>
                <ul class="nav-list">
                    <li><a href="/estudiantes" class="nav-link">
                            <mat-icon>keyboard_backspace</mat-icon> Atrás
                        </a></li>
                </ul>
            </nav>
        </div>
    </section>
</header>

<div class="card-container">
    <mat-card class="mat-card">
        <mat-card-content>
            <div class="container">
                <h1 class="h1-bold-center">{{ titulo }}</h1>
                <!-- Agrega la clase "selector-container" al contenedor de los selectores -->
                <div class="form-group row selector-container">
                    <mat-form-field class="selector" appearance="outline">
                        <mat-label>Seleccione un Año</mat-label>
                        <mat-select [(ngModel)]="selectedYear" name="year" required matInput
                            placeholder="Seleccione un Año">
                            <mat-option *ngFor="let year of years" [value]="year">{{year === 0 ? 'Todos' :
                                year}}</mat-option>
                        </mat-select>
                        <mat-error>
                            Año es Requerido
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="selector" appearance="outline">
                        <mat-label>Seleccione una Carrera</mat-label>
                        <mat-select [(ngModel)]="selectedCarrera" name="carrera" required matInput
                            placeholder="Seleccione una Carrera">
                            <!-- Usa la variable "carreras" para llenar el selector -->
                            <mat-option *ngFor="let carrera of carreras"
                                [value]="carrera.value">{{carrera.viewValue}}</mat-option>
                        </mat-select>
                        <mat-error>
                            Carrera es Requerida
                        </mat-error>
                    </mat-form-field>
                </div>
                <button type="submit" mat-raised-button color="primary" (click)="cargarPruebas()">Consultar</button>
            </div>
            <br>
        </mat-card-content>
    </mat-card>

    <mat-card class="mat-card">
        <mat-card-content>
            <div class="container">
                <br>
                <h3 class="h1-bold-center">RESUMEN DEL AÑO: {{ selectedYear === 0 ? 'Todos los años' : selectedYear }}
                </h3>
                <h3 class="h1-bold-center">CARRERA: {{ getSelectedCarreraText() }}</h3>
                <h3 class="h1-bold-center">SATISFACTORIOS DE AMBAS PRUEBAS: {{ totalEstudiantes }}</h3>
                <h3 class="h1-bold-center">TOTAL DE ESTUDIANTES: {{ totalEstudiantesEvaluados }}</h3>
            </div>
            <br>
            <div class="button-container">
                <button class="btn btn-success align-icon" type="button" (click)="exportarExcel()">
                    <mat-icon class="icon">save_alt</mat-icon> Exportar a Excel</button>
            </div>
            <br>
        </mat-card-content>
    </mat-card>
</div>

<div class="container">
    <table class="table table-striped" *ngIf="estudiantes && estudiantes.length > 0">
        <thead class="table-light">
            <tr>
                <th scope="col" class="table-header-center">NOV/Carnet</th>
                <th scope="col" class="table-header-center">Nombre</th>
                <th scope="col" class="table-header-center">Carrera</th>
                <th scope="col" class="table-header-center">Fecha</th>
                <th scope="col" class="table-header-center">Resultado</th>
            </tr>
        </thead>
        <tbody class="table-group-divider">
            <ng-container *ngFor="let estudiante of estudiantes">
                <tr>
                    <td class="table-header-center"> {{estudiante.nov }}</td>
                    <td class="table-header-center">{{estudiante.nombre}}</td>
                    <td class="table-header-center">{{getNombreCarrera(estudiante.car)}}</td>
                    <td class="table-header-center" type="date">{{calculaUltimaFecha(estudiante) | date: 'dd/MM/yyyy'}}
                    </td>
                    <td class="table-header-center">{{calculaResultadoGeneral(estudiante)}}</td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>
<br>
<br>
<br>